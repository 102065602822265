import { KeyValue } from '@angular/common';
import { ValidationErrors } from '@angular/forms';
import { BettingThreshold, SettingValue } from '../accounts/settings';
import { IdName, idNames } from '../common/id-name';
import { OddFormat } from '../common/odd-format';
import { ITicketRequestSelection } from './book-bet';
import { IBetSlipGroup, IBetSlipType } from './enum';
import { ISportEvent, ISportEventSelection } from './sportEvent';

export interface MinMax {
  min: number;
  max: number;
}

export interface IBetSlipCombination {
  amount: number;
  quantity: number
}

export class IBetSlipSelection {
  blocked? = false;
  sportEvent: Partial<ISportEvent>;
  marketSelection?: Partial<ISportEventSelection>;

  constructor(selection?: IBetSlipSelection) {
    if (selection) {
      Object.assign(this, selection);
    }
  }
}

export class IBetSlipAmounts {
  stake: number;
  stakeTax?: number;
  winning?: MinMax;
  winTax?: MinMax
}

export class IBetSlip {
  betTypeId: IBetSlipType;
  multiplier:number;
  selections?: Array<IBetSlipSelection>;
  amounts?: IBetSlipAmounts;
  status: 'empty' | 'selections' | 'placed';
  jackpotId?: number;
  settings?: IBetSlipSettings;
  combinations?: Array<{ amount: number, betType: number, quantity: number }>;

  constructor(init?: Partial<IBetSlip>, oldSlip?: IBetSlip) {
    this.selections = init?.selections || [];
    this.status = this.selections.length ? 'selections' : 'empty';
    // keep old settings
    this.settings = oldSlip ? oldSlip.settings : {acceptOddsChanges: AcceptOddsChangesTypes.always, stake: 100, oddFormat: OddFormat.Decimal};
    Object.assign(this, init);
  }
}

export interface IBetSlipBonus {
  minimumRequiredMultiplier: number;
  includeDiscarded: number;
  prematch: Array<KeyValue<number, number>>;
  live: Array<KeyValue<number, number>>;
  mixed: Array<KeyValue<number, number>>;
}

export interface BetSlipError {
  error: ValidationErrors;
  context?: string;
}

interface Limits {
  maxBet: number;
  maxBetCombination: number;
  maxEvents: number;
  maxOdd: number;
  maxOutcomes: number;
  maxWin: number;
  maxWinCombination: number;
  minBet: number;
  minBetCombination: number;
  maxBetTwins?: number;
  maxNumberTwins?: number;
  maxWinTwins?: number;
}

interface BetTypeLimits {
  integral: Limits;
  single: Limits;
  multiple?: any;
  system?: Limits;
}

class IBetSlipSettingLimits {
  live?: BetTypeLimits;
  mixed?: BetTypeLimits;
  prematch?: BetTypeLimits;
}

class IBetSlipSettingParameters {
  canPlayLiveBetting: boolean;
  canPlayPreMatch: boolean;
  canPlaySingle: boolean;
  canPlaySystem: boolean;
}

export interface IBetSlipSettings { // todo: probably can be switched with config
  bonus?: IBetSlipBonus;
  oddFormat?: OddFormat;
  acceptOddsChanges?: number;
  stake?: number;

  template?: {
    limits: IBetSlipSettingLimits;
    parameters: IBetSlipSettingParameters;
  }
}

export interface IBetSlipConfig {
  bonus: IBetSlipBonus;
  settingValues: SettingValue[]
  bettingThresholds: BettingThreshold[];
}

/*
export interface ITicketRequestSelection {
  blocked: boolean;
  oddId: number;
  value: number;
}
*/

export class ITicketRequest {
  amounts: IBetSlipAmounts;
  betTypeId: IBetSlipType;
  betGroupId: IBetSlipGroup;
  selections: Array<ITicketRequestSelection>;
  combination: Array<any>;
  jackpotId?: number;
  acceptOddsChanges: AcceptOddsChangesTypes;

  constructor(form: IBetSlip) {
    this.selections = form.selections.flatMap(x => ({oddId: x.marketSelection.oddId, value: x.marketSelection.oddValue, blocked: x.blocked}));
    this.amounts = form.amounts;
    this.betTypeId = form.betTypeId;
    // this.betGroupId = form.betGroupId;
    this.acceptOddsChanges = form.settings.acceptOddsChanges;
  }
}

export enum AcceptOddsChangesTypes {
  never = 1,
  always = 3,
  onlyHigh = 2,
}

export namespace AcceptOddsChangesTypes {
  export function values(): Array<IdName<string>> {
    return idNames(AcceptOddsChangesTypes);
  }
}
