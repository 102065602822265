import { IdName, idNameString } from './id-name';

export enum OddFormat {
  Decimal = 'Decimal',
  American = 'American',
  Fractional = 'Fractional',
  HongKong = 'HongKong',
  Indonesian = 'Indonesian',
  Malesian = 'Malesian'
}

export namespace OddFormat {
  export function values(): Array<IdName<string, string>> {
    return idNameString(OddFormat);
  }
}

